import React, { Component, Fragment } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Layout as AppLayout, Alert, Drawer, ConfigProvider } from 'antd'
import { connect } from 'react-redux'
import loadable from '@loadable/component'
import 'moment/locale/th'
import 'moment/locale/en-au'
import localeTh from 'antd/es/locale/th_TH'
import localeEn from 'antd/es/locale/en_US'

import history from 'lib/history'
import '../../../styled/common.css'
import '../../../styled/mapmarker.css'

import * as authActions from 'modules/auth/actions'
import * as uiActions from 'modules/ui/actions'
import PrivateRoute from './PrivateRoute'
import Header from './Header'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import SliderBar from './SliderBar'
import { askForSoundPlayingText } from '../../../constants/constants'
import { StyledImageHeader } from '../../../styled/common-styled'
//////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////
////////                      Site Page                           ////////
//////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////

import Login from 'modules/auth/components/Login'
import OauthLogin from 'modules/oauth/components/OauthLogin'
import Maintenancedevice from 'modules/help/maintenancedevice/components/Maintenancedevice'
import Scoresummary from 'modules/reports/scoresummary/components/Scoresummary'
import Behaviordrivingbycompany from 'modules/reports/behaviordrivingbycompany/components/Behaviordriving'
import Behaviordriving from 'modules/reports/behaviordriving/components/Behaviordriving'
import Honeytoastplayback from 'modules/monitoring/honeytoastplayback/components/Honeytoastplayback'
import Honeytoastplaybackwebpage from 'modules/monitoring/honeytoastplaybackwebpage/components/Honeytoastplayback'
import Honeytoastdownloadfile from 'modules/monitoring/honeytoastdownloadfile/components/Honeytoastdownloadfile'
import Honeytoastdownloadfilewebpage from 'modules/monitoring/honeytoastdownloadfilewebpage/components/Honeytoastdownloadfile'
import Honeytoaststream from 'modules/monitoring/honeytoaststream/components/Video'
import Deliverytrip from 'modules/delivery-management/delivery-trip/components/Trip'
import Servicerepairdevice from 'modules/help/servicerepairdevice/components/Servicerepairdevice'
import Contactmanagement from 'modules/management/contactmanagement/components/Contactmanagement'
import Mdvrdownloadfile from 'modules/monitoring/mdvrdownloadfile/components/Mdvrdownloadfile'
import HomeDashboard from 'modules/home-dashboard/index/components/HomeDashboard'
import Fueluse from 'modules/reports/fueluse/components/Fueluse'

import Policy from 'modules/setting/policy/components/Policy'
import LineNotify from 'modules/setting/linenotify/components/LineNotify'

import GpsPerformanceDashboard from 'modules/monitoring/gps-performance-dashboard/components/GpsPerformanceDashboard'
import SafetyDashboard from 'modules/monitoring/safety-dashboard/components/SafetyDashboard'
import RepairingReport from 'modules/reports/repairingreport/components/RepairingReport'
import PartnerPerformReport from 'modules/reports/partnerperformreport/components/PartnerPerformReport'
import GpsVendorPayingSummaryReport from 'modules/reports/gpsvendorpayingsummaryreport/components/GpsVendorPayingSummaryReport'
import GpsVendorPayingReport from 'modules/reports/gpsvendorpayingreport/components/GpsVendorPayingReport'
import FuelNotification from 'modules/reports/fuelNotification/FuelNotification'

import Datavisibility from 'modules/master-data/datavisibility/components/Datavisibility'
import SummaryDashboard from 'modules/monitoring/summary-dashboard/components/SummaryDashboard'
import Profile from 'modules/management/profile/components/Profile'

import Schedulereport from 'modules/reports/schedulereport/components/Schedulereport'
import SummaryVehicleUsage from 'modules/reports/summary-vehicle-usage/Overviews'
import Routemaster from 'modules/delivery-management/route-master/components/Routemaster'
import MultiDrop from 'modules/monitoring/multi-drop/components/MultiDrop'

import Uom from 'modules/master-data/uom/components/Uom'
import Product from 'modules/master-data/product/components/Product'

import Trackingstatusdashboard from 'modules/tracking_status_dashboard/tracking_status/components/Trackingstatusdashboard'
import Smartdispatching from 'modules/smartdispatching/components/Smartdispatching'

import Distancereport from 'modules/reports/distancereport/components/Distancereport'
import Webcontent from 'modules/web-content/components/WebContent'
import GenerateUserToken from 'modules/master-data/generate-user-token/components/GenerateUserToken'

const VehicleContract = loadable(() =>
  import('modules/master-data/vehicle-contract/VehicleContractOverview'),
)

const Vehicleinformationdlt = loadable(() =>
  import(
    'modules/reports/vehicleinformationdlt/components/Vehicleinformationdlt'
  ),
)

const Alarmalertbyplateno = loadable(() =>
  import('modules/reports/alarmalertbyplateno/components/Alarmalert'),
)
const Alarmalertbyplatenoonyear = loadable(() =>
  import('modules/reports/alarmalertbyplatenoonyear/components/Alarmalert'),
)
const Alarmalertbydriver = loadable(() =>
  import('modules/reports/alarmalertbydriver/components/Alarmalert'),
)
const Alarmalertbydurationtime = loadable(() =>
  import('modules/reports/alarmalertbydurationtime/components/Alarmalert'),
)
const Drivingbehavior = loadable(() =>
  import('modules/reports/drivingbehavior/components/Alarmalert'),
)
const Drivinglicensescanbehavior = loadable(() =>
  import(
    'modules/reports/drivinglicensescanbehavior/components/Drivinglicensescan'
  ),
)
const Separatevehiclestatus = loadable(() =>
  import('modules/reports/separatevehiclestatus/components/Routereport'),
)
const Comparevehiclescco = loadable(() =>
  import('modules/reports/comparevehiclescco/components/Comparevehiclescco'),
)
const Alertdriverlicense = loadable(() =>
  import('modules/reports/alertdriverlicense/components/Alert'),
)
const Parkingareaoutside = loadable(() =>
  import('modules/reports/parkingareaoutside/components/Parking'),
)
const Parking = loadable(() =>
  import('modules/reports/parking/components/Parking'),
)
const Drivinglicensescanlog = loadable(() =>
  import(
    'modules/reports/drivinglicensescanlog/components/Drivinglicensescanlog'
  ),
)
const Speedoverlimitsummary = loadable(() =>
  import(
    'modules/reports/speedoverlimitsummary/components/Speedoverlimitsummary'
  ),
)
const Drivinglog = loadable(() =>
  import('modules/reports/drivinglog/components/Drivinglog'),
)
const Event = loadable(() => import('modules/reports/event/components/Event'))
const SafetyEvent = loadable(() =>
  import('modules/reports/safetyEvent/components/SafetyEvent'),
)
const Summarykpi = loadable(() =>
  import('modules/reports/summarykpi/components/Summarykpi'),
)
const AlarmalertbydriverNewformet = loadable(() =>
  import(
    'modules/reports/alarmalertfrequencybydriver/components/AlarmalertNewformet'
  ),
)
const Loginhistory = loadable(() =>
  import('modules/reports/loginhistory/components/Loginhistory'),
)
const Gpsstatus = loadable(() =>
  import('modules/reports/gpsstatus/components/Gpsstatus'),
)

const Trackingdata = loadable(() =>
  import('modules/reports/trackingdata/components/Trackingdata'),
)

const Truckusage = loadable(() =>
  import('modules/reports/truckusage/components/Truckusage'),
)

const Drivingdistance = loadable(() =>
  import('modules/reports/drivingdistance/components/Drivingdistance'),
)

const Temperature = loadable(() =>
  import('modules/reports/temperature/components/Temperature'),
)

const Maintenance = loadable(() =>
  import('modules/reports/maintenance/components/Maintenance'),
)

const Distfromlastmaintenance = loadable(() =>
  import(
    'modules/reports/distfromlastmaintenance/components/Distfromlastmaintenance'
  ),
)

const Truckcurrentlocation = loadable(() =>
  import(
    'modules/reports/truckcurrentlocation/components/Truckcurrentlocation'
  ),
)

const Driverinformation = loadable(() =>
  import('modules/reports/driverinformation/components/Driverinformation'),
)

const Numberofdaysworked = loadable(() =>
  import('modules/reports/numberofdaysworked/components/Numberofdaysworked'),
)

const Dozeoffrisk = loadable(() =>
  import('modules/reports/dozeoffrisk/components/Dozeoffrisk'),
)

const Delivery = loadable(() =>
  import('modules/reports/delivery/components/Delivery'),
)

const Tripsumbyveh = loadable(() =>
  import('modules/reports/tripsumbyveh/components/Tripsumbyveh'),
)

const Triptemperature = loadable(() =>
  import('modules/reports/triptemperature/components/Triptemperature'),
)

const Daily = loadable(() => import('modules/reports/daily/components/Daily'))

const Velocitysummary = loadable(() =>
  import('modules/reports/velocitysummary/components/Velocitysummary'),
)

const Truckengineon = loadable(() =>
  import('modules/reports/truckengineon/components/Truckengineon'),
)

const Truckengineoff = loadable(() =>
  import('modules/reports/truckengineoff/components/Truckengineoff'),
)

const Trucknotuse = loadable(() =>
  import('modules/reports/trucknotuse/components/Trucknotuse'),
)

const Drivinghour = loadable(() =>
  import('modules/reports/drivinghour/components/Drivinghour'),
)

const Tripsummary = loadable(() =>
  import('modules/reports/tripsummary/components/Tripsummary'),
)

const Drivingoverlimit = loadable(() =>
  import('modules/reports/drivingoverlimit/components/Drivingoverlimit'),
)

const Tripsumbylocation = loadable(() =>
  import('modules/reports/tripsumbylocation/components/Tripsumbylocation'),
)

const Insurance = loadable(() =>
  import('modules/reports/insurance/components/Insurance'),
)

const Vehicleperformanceconclude = loadable(() =>
  import(
    'modules/reports/vehicleperformanceconclude/components/Vehicleperformanceconclude'
  ),
)

const Refuel = loadable(() =>
  import('modules/reports/refuel/components/Refuel'),
)

const Riskbehaviour = loadable(() =>
  import('modules/reports/riskbehaviour/components/Riskbehaviour'),
)

const Vehicleinarea = loadable(() =>
  import('modules/reports/vehicleinarea/components/Vehicleinarea'),
)

const Maintenancedeviceconclude = loadable(() =>
  import(
    'modules/reports/maintenancedeviceconclude/components/Maintenancedeviceconclude'
  ),
)

//management
const Downloadcertificate = loadable(() =>
  import(
    'modules/management/downloadcertificate/components/Downloadcertificate'
  ),
)

// const Drivingdistance = loadable(() =>
//   import("modules/reports/MoveReport/drivingdistance/components/DrivingdistanceOverview")
// );

// const Truckusage = loadable(() =>
//   import("modules/reports/truckusage/components/Truckusage")
// );

// notification
const NotificationCenter = loadable(() =>
  import('modules/ui/components/NotificationCenter'),
)
// dashboard
const Tracking = loadable(() =>
  import('modules/monitoring/tracking/components/Tracking'),
)
const DashCameras = loadable(() =>
  import('modules/monitoring/dash-cameras/components/DashCameras'),
)
const AlertAlarm = loadable(() =>
  import('modules/monitoring/alert-alarm/components/AlertAlarm'),
)

const BookingOverview = loadable(() =>
  import('modules/monitoring/booking-overview/components/BookingOverview'),
)
const CashingOverview = loadable(() =>
  import('modules/monitoring/cashing-overview/components/CashingOverview'),
)
const EventMap = loadable(() =>
  import('modules/monitoring/event-map/components/EventMap'),
)
const ConcreteDelivery = loadable(() =>
  import('modules/monitoring/concrete-delivery/components/ConcreteDelivery'),
)
const ConcreteDeliveryManager = loadable(() =>
  import(
    'modules/monitoring/concrete-delivery-manager/components/ConcreteDeliveryManager'
  ),
)
// const ActivityOutSideArea = loadable(() =>
//   import("modules/monitoring/concrete-delivery/components/ActivityOutSideArea")
// );

const Replay = loadable(() =>
  import('modules/monitoring/replaynew/components/Replay'),
)

const DriverKpi = loadable(() =>
  import('modules/monitoring/driver-kpi/components/DriverKPI'),
)

const Video = loadable(() =>
  import('modules/monitoring/video/components/Video'),
)

const Trip = loadable(() =>
  import('modules/delivery-management/trip/components/Trip'),
)

const IsupplyDelivery = loadable(() =>
  import('modules/monitoring/isupply-delivery/components/Overview'),
)

// const SafetyDashboard = loadable(() =>
//   import("modules/monitoring/Safety-dashboard/components/SafetyDashboard")
// );

// const Policy = loadable(() =>
//   import("modules/setting/policy/components/Policy")
// );

// master data
const Customer = loadable(() =>
  import('modules/master-data/customer/components/Customer'),
)
const Usergroup = loadable(() =>
  import('modules/master-data/usergroup/components/Usergroup'),
)
const Pullvehiclesink = loadable(() =>
  import('modules/master-data/pullvehiclesink/components/Pullvehiclesink'),
)
const Permission = loadable(() =>
  import('modules/master-data/permission/components/Permission'),
)

const VehicleGroupOwerview = loadable(() =>
  import('modules/master-data/vehicleGroup/components/VehicleGroupOwerview'),
)
const RouteOverview = loadable(() =>
  import('modules/master-data/route/components/RouteOverview'),
)
const VehicleSharing = loadable(() =>
  import('modules/master-data/vehicle-sharing/components/VehicleSharing'),
)
const Facility = loadable(() =>
  import('modules/master-data/facility/components/FacilityOverviwe'),
)
const Vehicle = loadable(() =>
  import('modules/master-data/vehicle/components/VehicleOverview'),
)
const DriverUser = loadable(() =>
  import('modules/master-data/deviceuser/components/DeviceUserOverview'),
)
const LocationGroup = loadable(() =>
  import('modules/master-data/locationgroup/components/LocationGroupOverview'),
)
const SiteGroup = loadable(() =>
  import('modules/master-data/sitegroup/components/SiteGroupOverview'),
)
const NearbyLocation = loadable(() =>
  import(
    'modules/master-data/nearbylocation/componants/NearByLocationOverview'
  ),
)
const Zone = loadable(() =>
  import('modules/master-data/zone/components/ZoneOverview'),
)
const User = loadable(() =>
  import('modules/master-data/user/components/UserOverview'),
)

const Verifyemail = loadable(() =>
  import('modules/master-data/verifyemail/components/verifyemailOverview'),
)
const Refeul = loadable(() =>
  import('modules/master-data/refuel/components/RefuelOverview'),
)
const Partner = loadable(() =>
  import('modules/master-data/partner/components/PartnerOverview'),
)
const LocationSharing = loadable(() =>
  import(
    'modules/master-data/locations-sharing/components/LocationSharingOverview'
  ),
)
const PartType = loadable(() =>
  import('modules/master-data/parttype/components/PartTypeOverview'),
)
const PartGroup = loadable(() =>
  import('modules/master-data/partgroup/components/PartGroupOverview'),
)
const ManagementMaintenance = loadable(() =>
  import('modules/master-data/maintenance/components/MainntenencsOverview'),
)
const ExpenseInsurance = loadable(() =>
  import('modules/master-data/insurance/components/InsuranceOverview'),
)

const Company = loadable(() =>
  import('modules/master-data/company/components/CompanyOverview'),
)

const ServiceMoveDevice = loadable(() =>
  import('modules/help/servicemovedevice/components/ServiceMoveDeviceOverview'),
)
const ServiceInstallDevice = loadable(() =>
  import(
    'modules/help/serviceeinstalldevice/components/ServiceinstallOverview'
  ),
)

// ;('D:CPA-GPS-CENTER-REACTsrcmodulesmaster-datasub_materiallcomponantOverview.js')

// booking
const CenterPool = loadable(() =>
  import('modules/booking-pool/center-pool/components/CenterPool'),
)
const FinishedPool = loadable(() =>
  import('modules/booking-pool/finished-pool/components/FinishedPool'),
)
const CancelledPool = loadable(() =>
  import('modules/booking-pool/cancelled-pool/components/CancelledPool'),
)
const CashReceived = loadable(() =>
  import('modules/cashier/cash-received/components/CashReceived'),
)
const ReceiveHistory = loadable(() =>
  import('modules/cashier/receive-history/components/ReceiveHistory'),
)

// operation job

const Announcement = loadable(() =>
  import(
    'modules/operate-job/announcement/announcement-broadcast/components/Announcement'
  ),
)

const CpacPolicy = loadable(() =>
  import('modules/GPS-Dashborad/gps-policy/components/Policy'),
)

const CpacRequestcertificate = loadable(() =>
  import(
    'modules/GPS-Dashborad/requestcertificate/components/RequestCertificateOverview'
  ),
)

const IsupplyRequestMaterial = loadable(() =>
  import('modules/master-data/request-material/components/Overview'),
)

const IsupplyManageQueue = loadable(() =>
  import('modules/management/isupply-manage-queue/components/Overview'),
)

const IsupplyDailyQueue = loadable(() =>
  import('modules/monitoring/request-material-to-day/components/Overview'),
)

const IsupplyVehicleBlaclist = loadable(() =>
  import('modules/management/isupply_vehicle_blacklist/components/overview'),
)

const IsupplyDriverBlaclist = loadable(() =>
  import('modules/management/isupply_driver_blacklist/components/overview'),
)

const VehicleVisibilityPermission = loadable(() =>
  import(
    'modules/management/vehicleVisibilityPermission/VehicleVisibilityPermission'
  ),
)

const VehicleVisibilityPermissionGeneralData = loadable(() =>
  import(
    'modules/management/vehicleVisibilityPermissionGeneralData/VehicleVisibilityPermissionGeneralData'
  ),
)

const IsupplyPlantMiningContract = loadable(() =>
  import(
    'modules/master-data/isupply_plant_mining_contract/components/Overview'
  ),
)

const IsupplyDashBoard = loadable(() =>
  import('modules/monitoring/isupply_dashboard/components/Overview'),
)

const IsupplyDeliveryReport = loadable(() =>
  import('modules/reports/isupply-delivery-report/components/Overview'),
)

const IsupplyManageVehicle = loadable(() =>
  import('modules/management/isupply-manage-vehicle/components/Overview'),
)

const PrecastTransportationInformationWall = loadable(() =>
  import(
    'modules/management/cpac-precast-transportation-information-wall/components/Overview'
  ),
)

const PrecastTransportationInformationFloor = loadable(() =>
  import(
    'modules/management/cpac-precast-transportation-information-floor/components/Overview'
  ),
)

const PrecastSalesCloseInformation = loadable(() =>
  import('modules/management/cpac-sales-close-information/components/Overview'),
)

const PrecastDashBoard = loadable(() =>
  import('modules/monitoring/cpac-precast/comnents/Overview'),
)
const PrecastReport = loadable(() =>
  import('modules/reports/cpac-precast/components/Overview'),
)
const IsupplyVehicleStatusDailyJobs = loadable(() =>
  import(
    'modules/monitoring/isupply-vehicle-status-daily-jobs/components/Overview'
  ),
)
const IsupplyOverviewPerformanceDashboard = loadable(() =>
  import(
    'modules/monitoring/isupply_overiew_performance_dashboard/components/Overview'
  ),
)
const Fuelremainrate = loadable(() =>
  import('modules/reports/fuelremainrate/components/fuelRemainRate'),
)

const Tripbyengineoffon = loadable(() =>
  import('modules/reports/tripbyengineoffon/components/Tripbyengineoffon'),
)

const Rmcconcratetrip = loadable(() =>
  import('modules/reports/rmcconcretetrip/componants/Overview'),
)

const Unauthorizeunload = loadable(() =>
  import('modules/reports/unauthorizeunload/components/Overview'),
)

const AutoMoveSiteReport = loadable(() =>
  import('modules/reports/automovesite/components/Overview'),
)

const Dailydrivingsummaryreport = loadable(() =>
  import('modules/reports/daily-driving-summary-report/components/Overview'),
)
const GPSLostContactReport = loadable(() =>
  import('modules/reports/gps-lost-contact/components/Overview'),
)
const ActionLog = loadable(() =>
  import('modules/monitoring/action-log/components/Overview'),
)
const IsupplyMaterial = loadable(() =>
  import('modules/master-data/material/components/Overview'),
)
const GpsDisconnectOnTriptReport = loadable(() =>
  import('modules/reports/gpsdisconnectontrip/components/Overview'),
)
const IsupplyWeightHistory = loadable(() =>
  import('modules/reports/isupply-weighing-history/components/Overview'),
)
const VehicleType = loadable(() =>
  import('modules/master-data/vehicleType/componente/Overview'),
)

const BookingKeyCdas = loadable(() =>
  import('modules/master-data/booking-key-cdas/components/Overview'),
)
const Truckincorrectpoint = loadable(() =>
  import('modules/monitoring/truck-incorrect-point/components/Index.js'),
)

const SubMaterial = loadable(() =>
  import('modules/master-data/sub_material/componant/Overview'),
)

const UpdateImei = loadable(() =>
  import('modules/help/updateimei/components/UpdateImeiOverview'),
)

const UpdateVechicle = loadable(() =>
  import('modules/help/updatevehicle/components/UpdateVehicleOverview'),
)

const IsupplyMultidrop = loadable(() =>
  import('modules/master-data/isupply-multidrop/component/Overview'),
)

const IsupplySampleImage = loadable(() =>
  import('modules/master-data/format-sample-image/components/Overview'),
)

const TrucksPickupCard = loadable(() =>
  import('modules/master-data/trucks-pickup-card/components/Overview'),
)

const BookingDashboard = loadable(() =>
  import('modules/monitoring/isupply_booking_dashboard/components/Overview'),
)
const BookingOrder = loadable(() =>
  import('modules/master-data/isupply-booking-order/component/Overview'),
)

const IsupplySaleorder = loadable(() =>
  import('modules/master-data/isupply-saleorder/components/Overview'),
)

const ViewPermission = loadable(() =>
  import('modules/help/viewpermission/components/ViewPermissionOverview'),
)

const AutoMoveSiteOverview = loadable(() =>
  import('modules/monitoring/automovesite/AutoMoveSiteOverview'),
)

const AccountVehicleListHistory = loadable(() =>
  import(
    'modules/master-data/vehicle-sharing-system-avaliable-account/account-vehicle-list-history/Overview.js'
  ),
)
const ContractorVehicleManagement = loadable(() =>
  import(
    'modules/master-data/vehicle-sharing-system-avaliable-account/contractor-vehicle-management/Overview.js'
  ),
)

const ListOfVehicleAccount = loadable(() =>
  import(
    'modules/master-data/vehicle-sharing-system-avaliable-account/list-of-vehicle-account/Overview.js'
  ),
)

const ApproveRequestToUseVehicle = loadable(() =>
  import(
    'modules/master-data/vehicle-sharing-system-avaliable-account/approve-request-to-use-vehicle/Overview.js'
  ),
)

const MonitorGPS = loadable(() =>
  import('modules/monitoring/monitor-gps/components/Overview'),
)

const InterfaceMonitoring = loadable(() =>
  import('modules/monitoring/interface-monitoring/components/Overview'),
)

class Layout extends Component {
  state = {
    // collapsed: true,
    pageReload: false,
    notiCount: 0,
    haveNewNoti: false,
    i18n: i18n.th,
    langBtn: 'th',
    checkSelectLang: '',
    url: '',
  }

  changeLang = inputLng => {
    this.setState(
      {
        i18n: i18n[inputLng],
        langBtn: inputLng,
      },
      () => {
        this.props.setLanguageChange(inputLng)
      },
    )
  }

  checkPageReload() {
    if (window.performance) {
      if (
        window.performance.navigation.type == 1 &&
        history.action == 'POP' &&
        history.location.pathname != '/' &&
        !history.location.pathname.includes('/center_pool/detail')
      ) {
        this.setState({ pageReload: true })
      }
    }
  }

  componentDidMount() {
    this.props.loadCredentials()

    this.checkPageReload()

    const url = new URL(window.location.href)

    this.setState({
      url: url.pathname.substring(18, -1),
    })
  }

  setMsg = (title, msg) => this.props.setFlashMessage(title, msg)

  onRemoveCredentials = () => {
    this.props.removeCredentials()
  }

  langMap = {
    th: localeTh,
    en: localeEn,
  }

  render() {
    localeTh.Transfer.itemUnit = 'item'
    localeTh.Transfer.itemsUnit = 'items'

    return (
      <ConfigProvider locale={this.langMap[this.state.langBtn]}>
        <Router>
          <Fragment>
            {this.state.url.includes('automovesite') ? (
              <Route
                path="/automovesite"
                component={AutoMoveSiteOverview}
                done={this.props.done}
              />
            ) : this.state.url == '/verifyemail/check' ? (
              <Route
                path="/verifyemail/check"
                component={Verifyemail}
                done={this.props.done}
              />
            ) : !this.props.done ? (
              <OauthLogin auth={this.props.path} />
            ) : (
              <LangContext.Provider value={this.state.i18n}>
                <AppLayout>
                  <AppLayout
                    style={{ minHeight: `${window.innerHeight - 25}px` }}
                  >
                    <Header
                      langBtn={this.state.langBtn}
                      changeLang={this.changeLang}
                      checkSelectLang={this.state.checkSelectLang}
                    />

                    {/* {this.state.pageReload && (
                    // <Alert
                    //   message={askForSoundPlayingText}
                    //   type="info"
                    //   showIcon
                    //   closeText={"Enable"}
                    //   closable
                    //   style={{ margin: "4px 4px 4px 4px" }}
                    // />
                  // )} */}
                    <PrivateRoute
                      key="/profile"
                      path="/truckincorrectpoint"
                      component={props => <Truckincorrectpoint {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/profile"
                      path="/profile"
                      component={props => <Profile {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/notification"
                      path="/notification"
                      component={props => <NotificationCenter {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/center_pool"
                      path="/center_pool"
                      component={props => <CenterPool {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/finished_pool"
                      path="/finished_pool"
                      component={props => <FinishedPool {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/cancelled_pool"
                      path="/cancelled_pool"
                      component={props => <CancelledPool {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/tracking"
                      path="/tracking"
                      component={props => <Tracking {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/dash_cameras"
                      path="/dash_cameras"
                      component={props => <DashCameras {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/alert_alarm"
                      path="/alert_alarm"
                      component={props => <AlertAlarm {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/booking_overview"
                      path="/booking_overview"
                      component={props => <BookingOverview {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/cashing_overview"
                      path="/cashing_overview"
                      component={props => <CashingOverview {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/cash_received"
                      path="/cash_received"
                      component={props => <CashReceived {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/receive_history"
                      path="/receive_history"
                      component={props => <ReceiveHistory {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/customer"
                      path="/customer"
                      component={props => <Customer {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/usergroup"
                      path="/usergroup"
                      component={props => <Usergroup {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/report/alarmalertbyplateno"
                      path="/report/alarmalertbyplateno"
                      component={props => <Alarmalertbyplateno {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/alarmalertbydriver"
                      path="/report/alarmalertbydriver"
                      component={props => <Alarmalertbydriver {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/report/alarmalertfrequencybydriver"
                      path="/report/alarmalertfrequencybydriver"
                      component={props => (
                        <AlarmalertbydriverNewformet {...props} />
                      )}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/report/alarmalertbydurationtime"
                      path="/report/alarmalertbydurationtime"
                      component={props => (
                        <Alarmalertbydurationtime {...props} />
                      )}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/report/drivingbehavior"
                      path="/report/drivingbehavior"
                      component={props => <Drivingbehavior {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/report/drivinglicensescanbehavior"
                      path="/report/drivinglicensescanbehavior"
                      component={props => (
                        <Drivinglicensescanbehavior {...props} />
                      )}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/report/alarmalertbyplatenoonyear"
                      path="/report/alarmalertbyplatenoonyear"
                      component={props => (
                        <Alarmalertbyplatenoonyear {...props} />
                      )}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/report/separatevehiclestatus"
                      path="/report/separatevehiclestatus"
                      component={props => <Separatevehiclestatus {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/announcement_broadcast"
                      path="/announcement_broadcast"
                      component={props => <Announcement {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/event_map"
                      path="/event_map"
                      component={props => <EventMap {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/concrete_delivery_manager"
                      path="/concrete_delivery_manager"
                      component={props => (
                        <ConcreteDeliveryManager {...props} />
                      )}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/concrete_delivery"
                      path="/concrete_delivery"
                      component={props => <ConcreteDelivery {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/replay"
                      path="/replay"
                      component={props => <Replay {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/driver-kpi"
                      path="/driver-kpi"
                      component={props => <DriverKpi {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/report/comparevehiclescco"
                      path="/report/comparevehiclescco"
                      component={props => <Comparevehiclescco {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/report/alertdriverlicense"
                      path="/report/alertdriverlicense"
                      component={props => <Alertdriverlicense {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/report/parkingareaoutside"
                      path="/report/parkingareaoutside"
                      component={props => <Parkingareaoutside {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/pullvehiclesink"
                      path="/pullvehiclesink"
                      component={props => <Pullvehiclesink {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/location"
                      path="/location"
                      component={props => <Facility {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/user"
                      path="/user"
                      component={props => <User {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/generateusertoken"
                      path="/generateusertoken"
                      component={props => <GenerateUserToken {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/vehiclegroup"
                      path="/vehiclegroup"
                      component={props => <VehicleGroupOwerview {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/route"
                      path="/route"
                      component={props => <RouteOverview {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/vehicle"
                      path="/vehicle"
                      component={props => <Vehicle {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/parking"
                      path="/report/parking"
                      component={props => <Parking {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/drivinglicensescanlog"
                      path="/report/drivinglicensescanlog"
                      component={props => <Drivinglicensescanlog {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/"
                      path="/"
                      component={props => <HomeDashboard {...props} />}
                    />

                    <PrivateRoute
                      key="/report/speedoverlimitsummary"
                      path="/report/speedoverlimitsummary"
                      component={props => <Speedoverlimitsummary {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/drivinglog"
                      path="/report/drivinglog"
                      component={props => <Drivinglog {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/event"
                      path="/report/event"
                      component={props => <Event {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/safety_event"
                      path="/report/safety_event"
                      component={props => <SafetyEvent {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/summarykpi"
                      path="/report/summarykpi"
                      component={props => <Summarykpi {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/permission"
                      path="/permission"
                      component={props => <Permission {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/vehicle_sharing"
                      path="/vehicle_sharing"
                      component={props => <VehicleSharing {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/drivingdistance"
                      path="/drivingdistance"
                      component={props => <Drivingdistance {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/datavisibility"
                      path="/datavisibility"
                      component={props => <Datavisibility {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/loginhistory"
                      path="/report/loginhistory"
                      component={props => <Loginhistory {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/gpsstatus"
                      path="/report/gpsstatus"
                      component={props => <Gpsstatus {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/trackingdata"
                      path="/report/trackingdata"
                      component={props => <Trackingdata {...props} />}
                      done={this.props.done}
                    />

                    {/* <PrivateRoute
                      key="/verifyemail"
                      path="/verifyemail"
                      component={Verifyemail}
                      done={this.props.done}
                    /> */}

                    <PrivateRoute
                      key="/deviceuser"
                      path="/deviceuser"
                      component={props => <DriverUser {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/video"
                      path="/video"
                      component={props => <Video {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/locationgroup"
                      path="/locationgroup"
                      component={props => <LocationGroup {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/sitegroup"
                      path="/sitegroup"
                      component={props => <SiteGroup {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/nearbylocation"
                      path="/nearbylocation"
                      component={props => <NearbyLocation {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/refuel"
                      path="/refuel"
                      component={props => <Refeul {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/truckusage"
                      path="/report/truckusage"
                      component={props => <Truckusage {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/drivingdistance"
                      path="/report/drivingdistance"
                      component={props => <Drivingdistance {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/temperature"
                      path="/report/temperature"
                      component={props => <Temperature {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/maintenance"
                      path="/report/maintenance"
                      component={props => <Maintenance {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/distfromlastmaintenance"
                      path="/report/distfromlastmaintenance"
                      component={props => (
                        <Distfromlastmaintenance {...props} />
                      )}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/truckcurrentlocation"
                      path="/report/truckcurrentlocation"
                      component={props => <Truckcurrentlocation {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/driverinformation"
                      path="/report/driverinformation"
                      component={props => <Driverinformation {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/numberofdaysworked"
                      path="/report/numberofdaysworked"
                      component={props => <Numberofdaysworked {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/dozeoffrisk"
                      path="/report/dozeoffrisk"
                      component={props => <Dozeoffrisk {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/delivery"
                      path="/report/delivery"
                      component={props => <Delivery {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/triptemperature"
                      path="/report/triptemperature"
                      component={props => <Triptemperature {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/tripsumbyveh"
                      path="/report/tripsumbyveh"
                      component={props => <Tripsumbyveh {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/daily"
                      path="/report/daily"
                      component={props => <Daily {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/velocitysummary"
                      path="/report/velocitysummary"
                      component={props => <Velocitysummary {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/truckengineon"
                      path="/report/truckengineon"
                      component={props => <Truckengineon {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/truckengineoff"
                      path="/report/truckengineoff"
                      component={props => <Truckengineoff {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/drivinghour"
                      path="/report/drivinghour"
                      component={props => <Drivinghour {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/tripsummary"
                      path="/report/tripsummary"
                      component={props => <Tripsummary {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/drivingoverlimit"
                      path="/report/drivingoverlimit"
                      component={props => <Drivingoverlimit {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/tripsumbylocation"
                      path="/report/tripsumbylocation"
                      component={props => <Tripsumbylocation {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/insurance"
                      path="/report/insurance"
                      component={props => <Insurance {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/vehicleperformanceconclude"
                      path="/report/vehicleperformanceconclude"
                      component={props => (
                        <Vehicleperformanceconclude {...props} />
                      )}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/refuel"
                      path="/report/refuel"
                      component={props => <Refuel {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/trucknotuse"
                      path="/report/trucknotuse"
                      component={props => <Trucknotuse {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/riskbehaviour"
                      path="/report/riskbehaviour"
                      component={props => <Riskbehaviour {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/partner"
                      path="/partner"
                      component={props => <Partner {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/sharelocation"
                      path="/sharelocation"
                      component={props => <LocationSharing {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/vehicleinarea"
                      path="/report/vehicleinarea"
                      component={props => <Vehicleinarea {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/maintenancedeviceconclude"
                      path="/report/maintenancedeviceconclude"
                      component={props => (
                        <Maintenancedeviceconclude {...props} />
                      )}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/zone"
                      path="/zone"
                      component={props => <Zone {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/trip"
                      path="/trip"
                      component={props => <Trip {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/policy"
                      path="/policy"
                      component={props => <Policy {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/parttype"
                      path="/parttype"
                      component={props => <PartType {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/partgroup"
                      path="/partgroup"
                      component={props => <PartGroup {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/maintenance"
                      path="/maintenance"
                      component={props => <ManagementMaintenance {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/management/downloadcertificate"
                      path="/management/downloadcertificate"
                      component={props => <Downloadcertificate {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/management/contactmanagement"
                      path="/management/contactmanagement"
                      component={props => <Contactmanagement {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/mdvrdownloadfile"
                      path="/mdvrdownloadfile"
                      component={props => <Mdvrdownloadfile {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/servicerepairdevice"
                      path="/servicerepairdevice"
                      component={props => <Servicerepairdevice {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/deliverytrip"
                      path="/deliverytrip"
                      component={props => <Deliverytrip {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/insurance"
                      path="/insurance"
                      component={props => <ExpenseInsurance {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/company"
                      path="/company"
                      component={props => <Company {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/servicemovedevice"
                      path="/servicemovedevice"
                      component={props => <ServiceMoveDevice {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/vehicleinformationdlt"
                      path="/report/vehicleinformationdlt"
                      component={props => <Vehicleinformationdlt {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/honeytoastlivestream"
                      path="/honeytoastlivestream"
                      component={props => <Honeytoaststream {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/serviceinstalldevice"
                      path="/serviceinstalldevice"
                      component={props => <ServiceInstallDevice {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/honeytoastdownloadfile"
                      path="/honeytoastdownloadfile"
                      component={props => <Honeytoastdownloadfile {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/honeytoastdownloadfilewebpage"
                      path="/honeytoastdownloadfilewebpage"
                      component={props => (
                        <Honeytoastdownloadfilewebpage {...props} />
                      )}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/honeytoastplayback"
                      path="/honeytoastplayback"
                      component={props => <Honeytoastplayback {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/honeytoastplaybackwebpage"
                      path="/honeytoastplaybackwebpage"
                      component={props => (
                        <Honeytoastplaybackwebpage {...props} />
                      )}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/safety_dashboard"
                      path="/safety_dashboard"
                      component={props => <SafetyDashboard {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/gps_performance_dashboard"
                      path="/gps_performance_dashboard"
                      component={props => (
                        <GpsPerformanceDashboard {...props} />
                      )}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/summary_dashboard"
                      path="/summary_dashboard"
                      component={props => <SummaryDashboard {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/cpac-policy"
                      path="/cpac-policy"
                      component={props => <CpacPolicy {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/behaviordriving"
                      path="/report/behaviordriving"
                      component={props => <Behaviordriving {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/scoresummary"
                      path="/report/scoresummary"
                      component={props => <Scoresummary {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/behaviordrivingbycompany"
                      path="/report/behaviordrivingbycompany"
                      component={props => (
                        <Behaviordrivingbycompany {...props} />
                      )}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/cpac-requestcertificate"
                      path="/cpac-requestcertificate"
                      component={props => <CpacRequestcertificate {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/maintenancedevice"
                      path="/maintenancedevice"
                      component={props => <Maintenancedevice {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/repairingreport"
                      path="/report/repairingreport"
                      component={props => <RepairingReport {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/report/partnerperformreport"
                      path="/report/partnerperformreport"
                      component={props => <PartnerPerformReport {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/report/gpsvendorpayingsummaryreport"
                      path="/report/gpsvendorpayingsummaryreport"
                      component={props => (
                        <GpsVendorPayingSummaryReport {...props} />
                      )}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/gpsvendorpayingreport"
                      path="/report/gpsvendorpayingreport"
                      component={props => <GpsVendorPayingReport {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/fuel-notification"
                      path="/fuel-notification"
                      component={props => <FuelNotification {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/request-material"
                      path="/request-material"
                      component={props => <IsupplyRequestMaterial {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/isupply-delivery"
                      path="/isupply-delivery"
                      component={props => <IsupplyDelivery {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/isupply-manage-queue"
                      path="/isupply-manage-queue"
                      component={props => <IsupplyManageQueue {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/isupply-daily-request-material"
                      path="/isupply-daily-request-material"
                      component={props => <IsupplyDailyQueue {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/isupply-vehicle-blacklist"
                      path="/isupply-vehicle-blacklist"
                      component={props => <IsupplyVehicleBlaclist {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/isupply-driver-blacklist"
                      path="/isupply-driver-blacklist"
                      component={props => <IsupplyDriverBlaclist {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/vehiclevisibilitypermission"
                      path="/vehiclevisibilitypermission"
                      component={props => (
                        <VehicleVisibilityPermission {...props} />
                      )}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/vehiclevisibilitypermissiongeneraldata"
                      path="/vehiclevisibilitypermissiongeneraldata"
                      component={props => (
                        <VehicleVisibilityPermissionGeneralData {...props} />
                      )}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/delivery-contract"
                      path="/delivery-contract"
                      component={props => (
                        <IsupplyPlantMiningContract {...props} />
                      )}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/isupply-dashboard"
                      path="/isupply-dashboard"
                      component={props => <IsupplyDashBoard {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/isupply-delivery-report"
                      path="/isupply-delivery-report"
                      component={props => <IsupplyDeliveryReport {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/isupply-manage-vehicle"
                      path="/isupply-manage-vehicle"
                      component={props => <IsupplyManageVehicle {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/cpac-precast-transportation-information-wall"
                      path="/cpac-precast-transportation-information-wall"
                      component={props => (
                        <PrecastTransportationInformationWall {...props} />
                      )}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/cpac-precast-transportation-information-floor"
                      path="/cpac-precast-transportation-information-floor"
                      component={props => (
                        <PrecastTransportationInformationFloor {...props} />
                      )}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/cpac-sales-close-information"
                      path="/cpac-sales-close-information"
                      component={props => (
                        <PrecastSalesCloseInformation {...props} />
                      )}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/precast-dashboard"
                      path="/precast-dashboard"
                      component={props => <PrecastDashBoard {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/precast-report"
                      path="/precast-report"
                      component={props => <PrecastReport {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/isupply-status-vehicles-receive-daily-jobs"
                      path="/isupply-status-vehicles-receive-daily-jobs"
                      component={props => (
                        <IsupplyVehicleStatusDailyJobs {...props} />
                      )}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/isupply-overview-performance-dashboard"
                      path="/isupply-overview-performance-dashboard"
                      component={props => (
                        <IsupplyOverviewPerformanceDashboard {...props} />
                      )}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/schedulereport"
                      path="/schedulereport"
                      component={props => <Schedulereport {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/summary-fuel-usage"
                      path="/report/summary-fuel-usage"
                      component={props => <SummaryVehicleUsage {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/fuelremainrate"
                      path="/report/fuelremainrate"
                      component={props => <Fuelremainrate {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/report/tripbyengineoffon"
                      path="/report/tripbyengineoffon"
                      component={props => <Tripbyengineoffon {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/routemaster"
                      path="/routemaster"
                      component={props => <Routemaster {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/multi_drop"
                      path="/multi_drop"
                      component={props => <MultiDrop {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/rmcconcretetrip"
                      path="/report/rmcconcretetrip"
                      component={props => <Rmcconcratetrip {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/unauthorizeunload"
                      path="/report/unauthorizeunload"
                      component={props => <Unauthorizeunload {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/auto-move-site"
                      path="/report/auto-move-site"
                      component={props => <AutoMoveSiteReport {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/uom"
                      path="/uom"
                      component={props => <Uom {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/product"
                      path="/product"
                      component={props => <Product {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/driving-summary"
                      path="/report/driving-summary"
                      component={props => (
                        <Dailydrivingsummaryreport {...props} />
                      )}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/gps-lost-contact"
                      path="/report/gps-lost-contact"
                      component={props => <GPSLostContactReport {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/gps-disconnect-on-trip"
                      path="/report/gps-disconnect-on-trip"
                      component={props => (
                        <GpsDisconnectOnTriptReport {...props} />
                      )}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/report/fueluse"
                      path="/report/fueluse"
                      component={props => <Fueluse {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/action-log"
                      path="/action-log"
                      component={props => <ActionLog {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/linenotify"
                      path="/linenotify"
                      component={props => <LineNotify {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/material"
                      path="/material"
                      component={props => <IsupplyMaterial {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/isupply-weighing-history-report"
                      path="/isupply-weighing-history-report"
                      component={props => <IsupplyWeightHistory {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="truckstatusdashboard"
                      path="/truckstatusdashboard"
                      component={props => (
                        <Trackingstatusdashboard {...props} />
                      )}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="smartdispatching"
                      path="/smartdispatching"
                      component={props => <Smartdispatching {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="distancereport"
                      path="/report/distancereport"
                      component={props => <Distancereport {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="WebContent"
                      path="/WebContent"
                      component={props => <Webcontent {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/vehicle-type"
                      path="/vehicle-type"
                      component={props => <VehicleType {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/interface-key-cdas"
                      path="/interface-key-cdas"
                      component={props => <BookingKeyCdas {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/sub-material"
                      path="/sub-material"
                      component={props => <SubMaterial {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/updateimei"
                      path="/updateimei"
                      component={props => <UpdateImei {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/viewuserpermission"
                      path="/viewuserpermission"
                      component={props => <ViewPermission {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/updatevehicle"
                      path="/updatevehicle"
                      component={props => <UpdateVechicle {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/multidrop"
                      path="/multidrop"
                      component={props => <IsupplyMultidrop {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/sample-image"
                      path="/sample-image"
                      component={props => <IsupplySampleImage {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/truck-pickup-card"
                      path="/truck-pickup-card"
                      component={props => <TrucksPickupCard {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/isupply-booking-scheduler/week"
                      path="/isupply-booking-scheduler/week"
                      component={props => <BookingDashboard {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/isupply-booking-scheduler/day"
                      path="/isupply-booking-scheduler/day"
                      component={props => <BookingDashboard {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/isupply-booking-scheduler/month"
                      path="/isupply-booking-scheduler/month"
                      component={props => <BookingDashboard {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/booking-order"
                      path="/booking-order"
                      component={props => <BookingOrder {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/isupply-saleorder"
                      path="/isupply-saleorder"
                      component={props => <IsupplySaleorder {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/vehicle-contract"
                      path="/vehicle-contract"
                      component={props => <VehicleContract {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/account-vehicle-list-history"
                      path="/account-vehicle-list-history"
                      component={props => (
                        <AccountVehicleListHistory {...props} />
                      )}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/contractor-vehicle-management"
                      path="/contractor-vehicle-management"
                      component={props => (
                        <ContractorVehicleManagement {...props} />
                      )}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/list-of-vehicle-account"
                      path="/list-of-vehicle-account"
                      component={props => <ListOfVehicleAccount {...props} />}
                      done={this.props.done}
                    />
                    <PrivateRoute
                      key="/approve-request-to-use-vehicle"
                      path="/approve-request-to-use-vehicle"
                      component={props => (
                        <ApproveRequestToUseVehicle {...props} />
                      )}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/monitor-gps"
                      path="/monitor-gps"
                      component={props => <MonitorGPS {...props} />}
                      done={this.props.done}
                    />

                    <PrivateRoute
                      key="/monitoring/interface-monitoring"
                      path="/monitoring/interface-monitoring"
                      component={props => <InterfaceMonitoring {...props} />}
                      done={this.props.done}
                    />
                  </AppLayout>
                </AppLayout>
              </LangContext.Provider>
            )}
          </Fragment>
        </Router>
      </ConfigProvider>
    )
  }
}
//export default Layout;

const mapStateToProps = ({
  auth: { accessToken, done, profile, tokenExp },
  ui: { flash, language },
}) => ({
  accessToken,
  done,
  profile,
  flash,
  tokenExp,
  language,
})
const mapDispatchToProps = {
  removeCredentials: authActions.removeCredentials,
  loadCredentials: authActions.loadCredentials,
  setLanguageChange: uiActions.setLanguageChange,
  //   loadFavoriteMenu: authActions.loadFavoriteMenu.request,
  clearFlashMessage: uiActions.clearFlashMessage,
  setFlashMessage: uiActions.setFlashMessage,
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
