import { LOAD_TRACKING_COLUMNS, SET_TRACKING_COLUMNS } from './actions'

const initialState = {
  columns: [],
  show_minimal_marker: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TRACKING_COLUMNS.REQUEST:
      const profile = JSON.parse(localStorage.getItem('profile')) || {}
      const tracking = JSON.parse(profile.config).menu.tracking

      return {
        ...state,
        columns: tracking.columns,
        show_minimal_marker: tracking.show_minimal_marker,
      }
    case SET_TRACKING_COLUMNS.REQUEST:
      const {
        payload: { columns, showMinimalMarker },
      } = action

      let profileData = JSON.parse(localStorage.getItem('profile')) || {}

      profileData.config = JSON.parse(profileData.config) || {}

      let getShowminimalMarker = showMinimalMarker
      if (typeof showMinimalMarker == 'undefined' || showMinimalMarker == null)
        getShowminimalMarker = false

      const updatedConfig = {
        ...profileData.config,
        menu: {
          ...profileData.config.menu,
          tracking: {
            ...profileData.config.menu.tracking,
            columns,
            show_minimal_marker: getShowminimalMarker,
          },
        },
      }

      localStorage.setItem(
        'profile',
        JSON.stringify({
          ...profileData,
          config: JSON.stringify(updatedConfig),
        }),
      )

      return {
        columns,
        show_minimal_marker: getShowminimalMarker,
      }

    default:
      return state
  }
}
