import { createTypes } from 'lib/action'

const LOAD_TRACKING_COLUMNS = createTypes('trackingColumns', 'load')
const SET_TRACKING_COLUMNS = createTypes('trackingColumns', 'set')

function loadTrackingColumns() {
  return {
    type: LOAD_TRACKING_COLUMNS.REQUEST,
    payload: {},
  }
}

function changeConfigTrackingColumns(columns, showMinimalMarker) {
  return {
    type: SET_TRACKING_COLUMNS.REQUEST,
    payload: { columns, showMinimalMarker },
  }
}

export {
  LOAD_TRACKING_COLUMNS,
  SET_TRACKING_COLUMNS,
  changeConfigTrackingColumns,
  loadTrackingColumns,
}
