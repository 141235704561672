import React, { Component } from 'react'

import { connect } from 'react-redux'
import { Alert, Spin, Icon } from 'antd'
// import BgImage from '/img/bgg.png'
import HomeDashboard from 'modules/home-dashboard/index/components/HomeDashboard'
import {
  PureBackgroundStyledContent,
  StyledLogo,
  StyledLogoiSupply,
  StyledDiv,
  StyledRow,
  StyledCol,
} from '../../../styled/common-styled'
import { checkOauthLogin, removeCredentials } from '../actions'
import Form from './Form'
import FormLoginByToken from './FormLoginByToken'
import FormLogin from '../../auth/components/Form'
import { checkLogin } from '../../auth/actions'

class OauthLogin extends Component {
  state = {
    bluenetLoading: false,
  }

  componentDidMount() {
    const search = window.location.search
    const params = new URLSearchParams(search)
    const userType = params.get('userType')
    const userName = params.get('userName')
    const jwt = params.get('jwt')
    const truckNo = params.get('truckNo')
    //const history = useHistory();

    if (typeof userType != 'undefined' && typeof userName != 'undefined') {
      if (userType && userName && userType != '' && userName != '') {
        this.setState({ bluenetLoading: true })
        this.props.removeCredentials()
        this.props.checkLogin({
          username: userName,
          password: 'password',
          loginType: 'bluenet-via-url',
          userType: userType,
          jwt: jwt,
          truckNo: truckNo,
          cb: () => window.location.replace('/tracking/?truckNo=' + truckNo),
        })
        //history.push("/tracking/?truckNo=" + truckNo);
      }
    }
  }

  checkLogin = values => {
    this.props.checkOauthLogin(values)
  }

  checkLoginForm = values => {
    let self = this
    self.props.checkLogin(values)
  }
  onClickBack = () => {
    window.history.back()
  }
  render() {
    let alertMsg = ''
    if (typeof this.props.location !== 'undefined') {
      if (
        typeof this.props.location.data != 'undefined' &&
        typeof this.props.location.data.msg != ''
      ) {
        alertMsg = (
          <Alert
            message="เรียนผู้ใช้บริการ"
            description={this.props.location.data.msg}
            type="warning"
            showIcon
            closable
            style={{ margin: '0px 0px 15px 0px' }}
          />
        )
      }
    }
    const url = new URL(window.location.href)

    return this.props.auth.done ? (
      <PureBackgroundStyledContent>
        <HomeDashboard />
      </PureBackgroundStyledContent>
    ) : (
      <StyledDiv img={'/img/bgg.png'}>
        <StyledRow>
          <StyledCol
            xs={{ span: 18, offset: 3 }}
            sm={{ span: 18, offset: 3 }}
            md={{ span: 9, offset: 8 }}
            lg={{ span: 7, offset: 8 }}
            xl={{ span: 7, offset: 8 }}
          >
            <div
              style={{
                backgroundColor: '#fff',
                padding: '10% 40px 10% 40px',
                borderRadius: '25px',
                boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
              }}
            >
              {url.pathname == '/logincpac' ? (
                <Icon
                  type="arrow-left"
                  onClick={this.onClickBack}
                  style={{ color: '#ff0b0b' }}
                />
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_ENDPOINT == 'isupply' ? (
                <StyledLogoiSupply span={24} src="/img/isupply.png" alt="" />
              ) : (
                <StyledLogo span={24} src="/img/2.png" alt="" />
              )}
              <Spin
                spinning={
                  this.props.oauth.loading ||
                  this.props.auth.loading ||
                  this.state.bluenetLoading
                }
                size="large"
                style={{ height: '100%', margin: '0px 20px' }}
              >
                {url.pathname == '/logincpac' ? (
                  <FormLogin
                    auth={this.props.auth}
                    onSubmit={this.checkLoginForm}
                  />
                ) : url.pathname == '/login-by-token' ? (
                  <FormLoginByToken
                    auth={this.props.auth}
                    onSubmit={this.checkLoginForm}
                  />
                ) : (
                  <Form auth={this.props.auth} onSubmit={this.checkLogin} />
                )}
              </Spin>
            </div>
          </StyledCol>
        </StyledRow>
      </StyledDiv>
    )
  }
}

export default connect(
  ({ token, profile, auth, oauth }) => ({ token, profile, auth, oauth }),
  {
    checkLogin: checkLogin.request,
    checkOauthLogin: checkOauthLogin.request,
    removeCredentials,
  },
)(OauthLogin)
