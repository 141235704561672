import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { Row, Col, Spin, Button, Form, Select } from 'antd'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import { GeneralStyledContent } from '../../../../styled/common-styled'
import AuthorizeComponent from '../../../auth/components/AuthorizeComponent'
import Iframe from 'react-iframe'
import { Formik } from 'formik'
import * as yup from 'yup'

const FormItem = Form.Item

const Index = props => {
  let { auth, profile, accessToken } = props

  useEffect(() => {
    getVehicle()
  }, [])

  const [vehicleList, setVehicleList] = useState([])

  const [url, setUrl] = useState('')

  const getVehicle = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/video-download`,
        {
          vehicle_visibility: profile.vehicle_visibility,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )
      setVehicleList(res.data.data)
    } catch (err) {
      console.log(err.message)
    }
  }

  const { Option } = Select

  const onSearch = val => {}

  const onSubmit = async id => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/video-download/${id}`,
        {},
        { headers: {} },
      )
      setUrl(res.data.data)
      //   setUrl(
      //     'http://localhost:3000/honey-toast-download-file-webpage?id=355715092898430',
      //   )
    } catch (err) {
      console.log(err.message)
    }
  }

  return (
    <AuthorizeComponent {...props} matching_name="honeytoastdownloadfile">
      <GeneralStyledContent>
        <Spin spinning={false}>
          <Row gutter={24}>
            <Col span={24}>
              <div className="semi-bold-16" style={{ color: 'black' }}>
                <LangContext.Consumer>
                  {i18n => i18n.h.honeytoastdownloadfile}
                </LangContext.Consumer>
              </div>
            </Col>
            <Col span={24} align="center">
              <div style={{ color: 'red', fontSize: 20, fontWeight: 'bold' }}>
                <LangContext.Consumer>
                  {i18n => i18n.n.notiDownloadVideo}
                </LangContext.Consumer>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <LangContext.Consumer>
                {i18n => (
                  <Formik
                    initialValues={{
                      plate_no: '',
                    }}
                    validate={values => {
                      // let errors = {};
                      // return errors;
                    }}
                    validationSchema={yup.object().shape({
                      plate_no: yup
                        .string()
                        .required(i18n.p.pleaseSelectVehicleRegistration),
                    })}
                    onSubmit={values => {
                      onSubmit(values.plate_no)
                    }}
                    render={({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      handleFocus,
                      setFieldValue,
                      resetForm,
                    }) => (
                      <div>
                        <Form onSubmit={handleSubmit}>
                          <Row>
                            <Col
                              span={2}
                              style={{
                                textAlign: 'right',
                                top: '10px',
                                marginRight: '5px',
                              }}
                            >
                              {
                                <span className="regular-bold-14">
                                  <LangContext.Consumer>
                                    {i18n => i18n.p.plateNoVehicleCode}
                                  </LangContext.Consumer>
                                </span>
                              }
                              <span style={{ color: 'red' }}>*</span>
                            </Col>
                            <Col span={4}>
                              <FormItem
                                validateStatus={
                                  touched.plate_no && errors.plate_no && 'error'
                                }
                                help={touched.plate_no && errors.plate_no}
                              >
                                <Select
                                  showSearch
                                  optionFilterProp="children"
                                  onChange={e => {
                                    setFieldValue('plate_no', e)
                                  }}
                                  onSearch={onSearch}
                                  filterOption={(input, option) =>
                                    option.props.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  value={values.plate_no}
                                >
                                  {vehicleList.map(item => (
                                    <Option value={item.id}>
                                      {item.plate_no}
                                    </Option>
                                  ))}
                                </Select>
                              </FormItem>
                            </Col>

                            <Col span={3} style={{ marginLeft: '10px' }}>
                              <FormItem>
                                <Button htmlType="submit" type="primary">
                                  <LangContext.Consumer>
                                    {i18n => i18n.a.apply}
                                  </LangContext.Consumer>
                                </Button>
                              </FormItem>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    )}
                  />
                )}
              </LangContext.Consumer>
            </Col>
          </Row>
          <Row>
            <Iframe
              url={url}
              width="100%"
              height={window.innerHeight - 250 + 'px'}
              allowFullScreen
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              frameBorder={4}
              loading={true}
            />
          </Row>
        </Spin>
      </GeneralStyledContent>
    </AuthorizeComponent>
  )
}

const mapStateToProps = ({
  honeytoastplayback,
  auth: { profile, accessToken },
}) => ({
  honeytoastplayback,
  profile,
  accessToken,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
